<template>
  <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
  >
    <template #button-content>
      <feather-icon
          :badge="totalNotification"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          {{ totalNotification }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-link
          v-for="notification in results"
          :key="notification.notificationId"
      >
        <b-media :class="!notification.isRead ? 'unread' : ''"
                 @click="goto(notification)"
        >
          <template #aside>
            <b-avatar
                size="32"
                :variant="notification.type"
            >
              <feather-icon :icon="notification.icon"/>
            </b-avatar>
          </template>
          <p class="media-heading d-flex justify-content-between">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
            <span class="text-muted font-small-1">
              {{ notification.actionDate }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="clearAll"
          v-if="totalNotification > 0"
      >Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import {NotificationService} from '@/services'

let notificationService = new NotificationService()

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  async created() {
    await this.listData()
  },
  data: () => ({
    perfectScrollbarSettings: {
      // maxScrollbarLength: 60,
      // wheelPropagation: false,
    },
    rawResults: [],
    results: [],

  }),
  computed: {
    totalNotification() {
      return this.results.filter(data => !data.isRead).length
    }
  },
  methods: {
    async listData() {
      notificationService.listData().then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.rawResults = data.results

          this.setNotificationFormat()
        } else {
          // this.$store.commit('main/setToastError', data)
        }
      })
    },
    setNotificationFormat() {
      this.results = this.rawResults.map(result => {
        let notificationType = result.notificationIsRead === 'Y' ? 'light-primary' : 'light-danger'
        let notificationIcon
        if(result.notificationType === 'workPermit') {
          notificationIcon = 'BookOpenIcon'
        } else if(result.notificationType === 'bookingOnsite') {
          notificationIcon = 'AirplayIcon'
        }

        return {
          id: result.notificationId,
          title: result.notificationTitle,
          subtitle: result.notificationDescription,
          module: result.notificationType,
          type: notificationType,
          icon: notificationIcon,
          isRead: result.notificationIsRead === 'Y',
          refId: result.notificationRefId,
          actionDate: result.actionDate.slice(0, -3).trim()
        }
      })
    },
    clearOne(pk) {
      return new Promise((resolve, reject) => {
        notificationService
            .clearOne(pk)
            .then(async saveResult => {
              if (saveResult.isSuccess) {
                resolve()
              } else {
                this.$store.commit('main/setToastError', saveResult.data)
                reject()
              }
            })
      })
    },
    clearAll() {
      notificationService
          .clearAll()
          .then(async saveResult => {
            if (saveResult.isSuccess) {
              await this.listData()
            } else {
              this.$store.commit('main/setToastError', saveResult.data)
            }
          })
    },
    goto(result) {
      let {name, query} = this.$route
      this.clearOne(result.id)
          .then(() => {
            if(result.module === 'workPermit') {
              if(name !== 'pageWork' || query.workId != result.refId) {
                let routeData = this.$router.resolve({name: 'pageWork', query: {workId: result.refId}})
                window.open(routeData.href, '_self')
              }
            } else if (result.module === 'bookingOnsite') {
              if(name !== 'pageOfflineTraining' || query.bookingId != result.refId) {
                let routeData = this.$router.resolve({name: 'pageOfflineTraining', query: {bookingId: result.refId}})
                window.open(routeData.href, '_self')
              }
            }
          })
    }
  }
}
</script>

<style lang="scss">
.media.unread {
  background-color: #f0f0f0;
}
</style>
