// ["home","workPermit","contractor","trainingCalendar","training","user","master","report","mCompany","mDepartment","mLocation","mPosition","mWorkType","mLicenseType","mAttachmentType","mTraining"]
// ["home","workPermit","contractor","training"]

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    id: 'home'
  },
  {
    title: 'Work Permit',
    route: 'pageWork',
    icon: 'BookOpenIcon',
    id: 'workPermit'
  },
  {
    title: 'User Management',
    route: 'pageUser',
    icon: 'UsersIcon',
    id: 'contractor'
  },
  {
    title: 'Online Training',
    route: 'pageTraining',
    icon: 'TvIcon',
    id: 'training'
  },
  {
    title: 'Offline Training',
    route: 'pageOfflineTraining',
    icon: 'AirplayIcon',
    id: 'trainingOffline'
  },
  {
    title: 'Master',
    icon: 'SettingsIcon',
    id: 'master',
    children: [
      {
        title: 'Company',
        route: 'pageMasterCompany',
        id: 'mCompany'
      },
      {
        title: 'Department',
        route: 'pageMasterDepartment',
        id: 'mDepartment'
      },
      {
        title: 'Location',
        route: 'pageMasterLocation',
        id: 'mLocation'
      },
      {
        title: 'Position',
        route: 'pageMasterPosition',
        id: 'mPosition'
      },
      {
        title: 'Work Type',
        route: 'pageMasterWorkType',
        id: 'mWorkType'
      },
      {
        title: 'Compliance',
        route: 'pageMasterCompliance',
        id: 'mCompliance'
      },
      {
        title: 'Attachment Type',
        route: 'pageMasterAttachmentType',
        id: 'mAttachmentType'
      },
      {
        title: 'Training Question',
        route: 'pageMasterQuestion',
        id: 'mTrainingQaA'
      },
    ],
  },
  {
    title: 'Report',
    icon: 'FileTextIcon',
    id: 'report',
    children: [
      {
        title: 'Work Permit Report',
        route: 'reportWorkPermit',
        id: 'r1'
      },
    ],
  },
]
