<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <locale />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.userFullName }}
            </p>
            <span class="user-status">{{ userData.userGroup }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :src="userData.userImage"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name: 'pageProfileEdit'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name: 'pageProfileChangePassword'}">
          <feather-icon
            size="16"
            icon="Edit2Icon"
            class="mr-50"
          />
          <span>Change Password</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    Locale,
    NotificationDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('scope')
      localStorage.removeItem('userData')

      // Redirect to login page
      window.location.href='/login'
    },
  }
}
</script>

<style lang="scss">
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 14rem;
}
</style>